<style type="text/css">
@import "~@/assets/css/common.css";
@import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }
  .queryForm .el-select,.queryForm .el-input{
    width: 150px !important;
  }
  .check-distri {
    line-height: 32px;
  }
  .el-tag{
    cursor: pointer;
  }
  /deep/ .el-dialog__header {
    background: rgb(197 197 197 / 40%) !important;
    padding: 20px;
    text-align: left !important;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  /deep/ .el-form-item__label {
    margin-bottom: 0 !important;
  }

  .flow-chart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .flow-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .flow-content {
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 10px;
    width: 200px;
  }

  .flow-content h3 {
    margin: 0 0 10px 0;
    font-size: 16px;
  }

  .flow-content p {
    margin: 5px 0;
    font-size: 14px;
  }

  .flow-arrow {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #f0f0f0;
    margin: 10px 0;
  }
</style>
<script>
import Layout from "@/views/layouts/main";
import {checkFile, getUploadLimit, uploadFiles} from "@/api/common/public";
import {
  informationApplyPersonal,
  editInformationApply,
  deleteInformationApply,
  findUserByKey,
  informationApplyDetail,
} from "@/api/admin/information/informationCollaboration.js"; 
import{
  getExamRecord
} from "@/api/admin/exam/examRecord.js";
import CheckFlow from "@/components/information-check-flow.vue";
import InformationDownload from "@/components/information-download.vue";
/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    CheckFlow,
    InformationDownload
  },
  data() {
    return {
      query: {
        keyword: '',
      },
      uploadModule: 'InformationApply',
      uploadLimit:{},
      tableList: [],
      allDataList: [],

      pageData: {
        pageNum: 1,
        pageSize: 100,
        total: 0
      },

      showModal: false,
      showDetail: false,
      dialogTitle: '',
      form: {
        applyId: '',
        title: '',
        sqr: '',
        sqsx: '',
        szdw: '',
        lxr: '',
        lxdh: '',
        sqsj: '',
        slr: '',
        lzzt: ''
      },
      detail: {
        info: {},
        checkList: [],
      },
      rules: {
        sqsx: [{ required: true, message: '请输入申请事项', trigger: 'blur' },
          { max: 100, message: '长度在 100 个字符以内', trigger: 'blur' }
        ],
        sqyt: [{ required: true, message: '请输入申请用途', trigger: 'blur' },
          { max: 100, message: '长度在 100 个字符以内', trigger: 'blur' }
        ], 
        szdw: [{ required: true, message: '请输入所在单位', trigger: 'blur' }],
        lxr: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        lxdh: [{ required: true, message: '请输入联系电话', trigger: 'blur' }], 
        slrzh: [{ required: true, message: '请选择受理人', trigger: 'change' }],
      },
      options: [],
      loading: false,
      examList: [],
      downloadForm:{},
      showDownload: false,
    };
  },
  methods: {
    // 获取数据列表
    getList() {
      informationApplyPersonal({...this.query}).then(res => {
        if (res.status) {
          this.tableList = res.data;
        }
      })
    },
    handleEdit(obj){
      this.showModal = true
      this.dialogTitle = obj?.applyId ? '编辑' : '新增'
      this.form = obj||{};
      if (this.form.slr) {
        this.options = [{ xm: this.form.slr, yhzh: this.form.slrzh, yddh: '' }];
      } else {
        this.options = [];
      }
    },
    handleDelete(obj){
      this.$confirm(`是否确认删除?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
          deleteInformationApply(obj.applyId).then(res => {
            if (res.status) {
              this.getList()
            }
          })
      })
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.pageNum = val
      this.getList();
    },
    shzt(level, val){
      if(level == 0 || level == 100){
        switch(val) {
          case '0':
            return '<span style="color: #FFA500;">待处理</span>'
          case '1':
            return '<span style="color: #1E90FF;">已受理</span>'
        }
      } else{
        switch(val) {
          case '0':
            return '<span style="color: #FFA500;">待审核</span>'
          case '1':
            return '<span style="color: #1E90FF;">通过</span>'
          case '2': 
            return '<span style="color: #FF0000;">不通过</span>'
        }
      }
    },
    lzzt(val){
      switch(val) {
        case '0':
          return '<span style="color: #FFA500;">待提交</span>'
        case '1':
          return '<span style="color: #1E90FF;">已提交</span>'
        case '2':
          return '<span style="color: #32CD32;">已受理</span>'
        case '3':
          return '<span style="color: #FF0000;">不通过</span>'
        case '4':
          return '<span style="color: #9370DB;">已审批</span>'
        case '5':
          return '<span style="color: #008000;">已办理</span>'
        default:
          return ''
      }
    },
    submitForm(type) {
      // 判断附件是否上传
      if (!this.form.fjdz) {
        this.$message.warning('请上传附件');
        return;
      }
      
      // 判断受理人是否填写
      if (!this.form.slrzh) {
        this.$message.warning('请填写受理人');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单验证通过,执行提交操作
          this.form.lzzt = type == 'submit'?'1':'0';
          editInformationApply(this.form).then(res => {
            if (res.status) {
              this.$message.success('操作成功');
              this.showModal = false;
              this.getList();
            }
          });
        }
      });
    },
    limit() {
      getUploadLimit({
        module: this.uploadModule,
      }).then(res => {
        if (res.status) {
          this.uploadLimit["ext"] = "." + res.data.extLimit.replaceAll(",", ",.");
          this.uploadLimit["size"] = res.data.sizeLimit;
        }
      });
    },
    uploadFile(file) {
      /** 上传背景文件 */
      var _this = this;
      file = file.raw;
      if (checkFile(_this, this.uploadLimit.size, this.uploadLimit.ext, file)) {
        _this.uploadStatus = "loading";
        uploadFiles(_this.uploadModule, file).then(res => {
          if (res.status) {
            var file = res.data[0];
            if (file.status) {
              this.$set(this.form, 'fjmc', file.name);
              this.$set(this.form, 'fjdz', file.fileId);
              _this.$message({
                type: "success",
                message: "上传成功"
              });
              this.fileUp = true;
            } else {
              _this.$message({
                type: "error",
                message: file.error
              });
            }
          }
          _this.uploadStatus = "";
        });
      }
    },
    remoteSearch(query) {
      if (query.length > 1) {
        this.loading = true;
        findUserByKey({key: query}).then(res => {
          this.loading = false;
          if(res.status) {
            this.options = res.data;
          } else {
            this.$message.warning('未查询到受理人信息');
            this.options = [];
          }
        }).catch(() => {
          this.loading = false;
        });
      } else {
        this.options = [];
      }
    },
    openFile(fileId) {
      window.open(this.getFileUrl(fileId, false), "_blank");
    },
    detailInfo(applyId) {
      this.showDetail = true;
      informationApplyDetail(applyId).then(res => {
        if(res.status) {
          this.detail = res.data;
        }
      });
    },
    examListDict(){
      getExamRecord({}).then(res => {
        if(res.status) {
          this.examList = res.data;
        }
      });
    },
    openDownload(obj) {
      this.downloadForm = obj;
      this.showDownload = true;
    },  
  },
  mounted() {
    this.getList();
    this.limit();
    this.examListDict();
  },
  watch: {
    'form.slrzh'(newVal) {
      const selectedUser = this.options.find(option => option.yhzh === newVal);
      if (selectedUser) {
        this.form.slr = selectedUser.xm;
      }
    }
  }

};
</script>

<template>
  <Layout>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList queryForm">
                <!-- <el-input class=" mr-2 " placeholder="查询申请人/申请事项"  v-model="query.keyword" size="small" maxlength="50" >
                </el-input>
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="getList"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button> -->
                <button type="button" class="btn btn-info h30 flexList mr-2" @click="handleEdit({})"><i
                    class="iconfont mr-2 icon-mb-search"></i>添加申请</button>
              </div>

            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                <tr>

                  <th style="width: 80px;">
                    <input type="checkbox" id="all" name="all"  /> 序号</th>
                  <th style="width: 150px;">申请人</th>
                  <th >申请事项</th>
                  <th style="width: 150px;">所在单位</th>
                  <th style="width: 120px;">联系人</th>
                  <th style="width: 120px;">联系电话</th>
                  <th style="width: 150px;">申请时间</th>
                  <th style="width: 120px;">受理人</th>
                  <th style="width: 120px;">流转状态</th>
                  <th style="width: 120px;">下载数据</th>
                  <th style="width: 120px;">流转详情</th>
                  <th style="width: 120px;">操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(obj,index) in tableList" :key="index">
                  <td>

                    {{index+1+(pageData.pageNum-1)*pageData.pageSize}}
                  </td>
                  <td>{{obj.sqr}}</td>
                  <td>{{obj.sqsx}}</td>
                  <td> {{obj.szdw}}</td>
                  <td> {{obj.lxr}}</td>
                  <td>{{obj.lxdh}}</td>
                  <td> {{obj.sqsj}}</td>
                  <td> {{obj.slr}}</td>
                  <td v-html="lzzt(obj.lzzt)"></td>
                  <td>
                    <el-button type="text" v-if="obj.lzzt === '5'" style="cursor: pointer"  @click='openDownload(obj)'>下载数据</el-button>
                  </td>
                  <td>
                    <el-button type="text" size="small" @click="detailInfo(obj.applyId)">流转详情</el-button>
                  </td>
                  <td>
                    <el-button type="text" size="small" @click="handleEdit(obj)">{{ obj.lzzt === '0'?'编辑':'查看' }}</el-button>
                    <template v-if="obj.lzzt === '0'">
                      <el-button type="text" size="small" @click="handleDelete(obj)">删除</el-button>
                    </template>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
      

          </div>
        </div>
      </div>


    </div>
    <!-- 添加-->
    <el-dialog 
      :visible.sync="showModal" 
      :close-on-click-modal="false" 
      :title="dialogTitle" 
      width="50%"
      center>
      <el-form ref="form"  label-position="right" :model="form" label-width="100px" :rules="rules">
        <el-form-item label="申请事项：" prop="sqsx">
          <el-input v-model="form.sqsx" maxlength="100"></el-input>
          <span style="color: red;">例如：申请提取xxxx考试xxx数据（如报名数据、缴费数据、审核通过数据、减免数据、职位统计数据等）</span>
        </el-form-item>
        <el-form-item label="申请用途：" prop="sqyt">
          <el-input v-model="form.sqyt" maxlength="100"></el-input>
          <span style="color: red;">例如:职位调剂、准考证编排、报名数据分析等</span>
        </el-form-item>
        <el-form-item label="所属考试：" prop="ssks">
          <el-select v-model="form.ssks" placeholder="请选择考试" >
            <el-option
              v-for="item in examList"
              :key="item.ksmc"
              :label="item.ksmc"
              :value="item.ksmc">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所在单位：" prop="szdw">
          <el-input v-model="form.szdw" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="联系人：" prop="lxr">
          <el-input v-model="form.lxr" maxlength="50"></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="lxdh">
          <el-input v-model="form.lxdh" maxlength="50"></el-input>
        </el-form-item> 
        <el-form-item label="受理人：" prop="slr">
          <el-select
            v-model="form.slrzh"
            filterable
            remote
            reserve-keyword
            placeholder="请输入姓名或手机搜索"
            :remote-method="remoteSearch"
            :loading="loading">
            <el-option
              v-for="item in options"
              :key="item.yhzh"
              :label="item.xm +(item.yddh?'（手机：'+item.yddh+'）':'')"
              :value="item.yhzh">
            </el-option>
          </el-select>
          <span style="color: #909399; font-size: 12px;margin-left: 10px;">(请输入姓名或手机搜索)</span>
        </el-form-item>
        <el-form-item label="上传附件：" >
          <a style="line-height: 36px;" v-if="form.fjdz" @click="openFile(form.fjdz)"  href="javascript:void(0);">{{ form.fjmc }}</a> 
          <el-upload class="upload-demo" style="width: 100%;text-align: left;" action="" :auto-upload="false" :on-change="uploadFile" size="small"
                           :accept="uploadLimit.ext" :show-file-list="false">
                  <el-button size="mini" plain style="display: inline;"><i
                      class="iconfont mr-1 icon-shangchuan"></i>{{ form.fjdz ? '重新上传' : '上传文件' }}</el-button>
                  <el-tag type="danger" class=" ml-2" style="height: 30px;">
                    支持上传{{ uploadLimit.ext }}文件，且不超过{{
                      uploadLimit.size / 1024 / 1024
                    }}M</el-tag>
                </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <template v-if="(form.lzzt||0)<1">
          <el-button type="success" @click="submitForm('save')">保存草稿</el-button>
          <el-button type="primary" @click="submitForm('submit')">提交审核</el-button>
        </template>
        <el-button @click="showModal = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog 
      :visible.sync="showDetail" 
      :close-on-click-modal="false" 
      title="流转详情" 
      width="50%"
      center>
      <div>
        <table style="width: 100%; table-layout: fixed; border-collapse: collapse;">
       
          <tr>
            <td style="width: 10%;padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">申请事项</td>
            <td style="width: 90%;padding: 10px; border-bottom: 1px solid #eee;" colspan="3">{{detail.info.sqsx}}</td>
          </tr>
       
          <tr>
            <td style="width: 10%; padding: 10px; border-bottom: 1px solid #eee; font-weight: bold;">流转详情</td>
            <td style="max-width: 90%;padding: 10px; border-bottom: 1px solid #eee;" colspan="3">
              <div style="overflow-x: auto;max-width: 100%;">
                <CheckFlow :check-list="detail.checkList" :info="detail.info" />
              </div>
            </td>
          </tr>
        </table>
       
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDetail = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog 
      :visible.sync="showDownload" 
      :close-on-click-modal="false" 
      title="下载数据" 
      width="25%"
      center>
        <InformationDownload :info-id="downloadForm.infoId" />
    </el-dialog>
  </Layout>
</template>
